import { useQuery } from 'react-query'
import api from 'services/api'

const getWarrantyCard = async (id: string) => {
  const { data } = await api.get(`/warranties/${id}`)

  return data
}

export function useGetWarrantyCard(id: string) {
  return useQuery(['warranty-card', id], () => getWarrantyCard(id))
}
