import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { UserFilter, UserForm, UserTable } from './components'
import { useGetUsers } from './hooks'
import { User } from './types'

export function Users() {
  const [userFormOpened, setUserFormOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetUsers({
    query: searchParams.get('query') || '',
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onCloseUserForm = useCallback(() => {
    setSelectedUser(undefined)
    setUserFormOpened(false)
    refetch()
  }, [])

  const onEditUser = useCallback((user: User) => {
    setSelectedUser(user)
    setUserFormOpened(true)
  }, [])

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Quản lý người dùng</Title>
        <Button
          leftIcon={<IconPlus size={14} />}
          onClick={() => setUserFormOpened(true)}
        >
          Tạo người dùng
        </Button>
      </Group>
      <UserFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper shadow="xs" p="md" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <UserTable
          users={data?.items || []}
          pagination={data?.meta}
          onEditUser={onEditUser}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={userFormOpened}
        onClose={onCloseUserForm}
        title={
          <Title order={3}>
            {selectedUser ? 'Cập nhật người dùng' : 'Tạo người dùng'}
          </Title>
        }
        padding="xl"
        size="left"
        position="right"
      >
        <UserForm user={selectedUser} onSubmitSuccess={onCloseUserForm} />
      </Drawer>
    </Container>
  )
}
