import { AppShell } from '@mantine/core'
import {
  IconBarcode,
  IconBuildingWarehouse,
  IconLicense,
  IconReceipt2,
  IconTruck,
  IconUsers,
} from '@tabler/icons'
import { useAuthContext } from 'providers'
import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { NavbarSimple } from '../navbar/navbar'

const data = [
  { link: '/customers', label: 'Quản lý khách hàng', icon: IconReceipt2 },
  { link: '/agents', label: 'Quản lý đại lý', icon: IconTruck },
  {
    link: '/distributors',
    label: 'Quản lý nhà phân phối',
    icon: IconBuildingWarehouse,
  },
  { link: '/warranty-cards', label: 'Quản lý thẻ bảo hành', icon: IconLicense },
  { link: '/users', label: 'Quản lý tài khoản', icon: IconUsers },
  { link: '/products', label: 'Quản lý hạng mục', icon: IconBarcode },
]

export function Layout() {
  const { user, logout } = useAuthContext()
  const navData = useMemo(() => {
    if (user?.role === 'ADMIN') {
      return data
    }
    if (user?.role === 'DISTRIBUTOR') {
      return [
        { link: '/customers', label: 'Quản lý khách hàng', icon: IconReceipt2 },
        { link: '/agents', label: 'Quản lý đại lý', icon: IconTruck },
        {
          link: '/warranty-cards',
          label: 'Quản lý thẻ bảo hành',
          icon: IconLicense,
        },
      ]
    }
    if (user?.role === 'AGENT') {
      return [
        { link: '/customers', label: 'Quản lý khách hàng', icon: IconReceipt2 },
        {
          link: '/warranty-cards',
          label: 'Quản lý thẻ bảo hành',
          icon: IconLicense,
        },
      ]
    }
    return []
  }, [])

  return (
    <AppShell
      padding="md"
      navbar={<NavbarSimple links={navData} onLogout={logout} />}
    >
      <Outlet />
    </AppShell>
  )
}
