import { useMutation } from 'react-query'
import api from 'services/api'

import { UpdateUser } from '../types'

const updateUser = async (updateUser: UpdateUser) => {
  const promises = []
  const submitUserData = {
    fullname: updateUser.fullname,
    password: updateUser.password,
  }

  promises.push(api.patch(`/users/${updateUser.id}`, submitUserData))

  if (updateUser.role === 'CUSTOMER') {
    const submitCustomerData = {
      fullname: updateUser.fullname,
      email: updateUser.email,
      birthday: updateUser.birthday,
      phone: updateUser.phone,
      address: updateUser.address,
      ward: updateUser.ward,
      district: updateUser.district,
      city: updateUser.city,
    }

    promises.push(
      api.patch(`/customers/${updateUser.customerId}`, submitCustomerData)
    )
  }

  await Promise.all(promises)
}

export function useUpdateUser() {
  return useMutation(updateUser)
}
