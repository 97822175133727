import { useMutation } from 'react-query'
import api from 'services/api'

import { UpdateCustomer } from '../types'

const updateCustomer = async (updateCustomer: UpdateCustomer) => {
  await api.patch(`/customers/${updateCustomer.id}`, updateCustomer)
}

export function useUpdateCustomer() {
  return useMutation(updateCustomer)
}
