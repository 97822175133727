import { useQuery } from 'react-query'
import api from 'services/api'

import { GetUsersParams } from '../types'

const getUsers = async (usersParams: GetUsersParams) => {
  const { data } = await api.get('users', {
    params: usersParams,
  })

  return data
}

export function useGetUsers(usersParams: GetUsersParams) {
  return useQuery(['users', usersParams], () => getUsers(usersParams))
}
