import { useMutation } from 'react-query'
import api from 'services/api'

import { UpdateDistributor } from '../types'

const updateDistributor = async (updateDistributor: UpdateDistributor) => {
  await api.patch(`/distributors/${updateDistributor.id}`, updateDistributor)
}

export function useUpdateDistributor() {
  return useMutation(updateDistributor)
}
