import { useMutation } from 'react-query'
import api from 'services/api'

import { CreateWarrantyCard } from '../types'

const createWarrantyCard = async (newWarranty: CreateWarrantyCard) => {
  const { data: warrantyData } = await api.post('/warranties', newWarranty)

  return warrantyData
}

export function useCreateWarrantyCard() {
  return useMutation(createWarrantyCard)
}
