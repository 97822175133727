import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { AgentForm } from 'components'
import { AgentTable } from 'components/agent-table/agent-table'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AgentFilter } from './components'
import { useGetAgents } from './hooks'
import { Agent } from './types'

export function Agents() {
  const [agentFormOpened, setAgentFormOpened] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState<Agent>()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetAgents({
    query: searchParams.get('query') || '',
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onCloseAgentForm = useCallback(() => {
    setSelectedAgent(undefined)
    setAgentFormOpened(false)
    refetch()
  }, [])

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onEditAgent = useCallback((distributor: Agent) => {
    setSelectedAgent(distributor)
    setAgentFormOpened(true)
  }, [])

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Quản lý đại lý</Title>
        <Button
          leftIcon={<IconPlus size={14} />}
          onClick={() => setAgentFormOpened(true)}
        >
          Tạo đại lý
        </Button>
      </Group>
      <AgentFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper shadow="xs" p="md" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <AgentTable
          path="/agents"
          agents={data?.items || []}
          pagination={data?.meta}
          onEditAgent={onEditAgent}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={agentFormOpened}
        onClose={onCloseAgentForm}
        title={
          <Title order={3}>
            {selectedAgent ? 'Cập nhà nhật đại lý' : 'Tạo nhà đại lý'}
          </Title>
        }
        padding="xl"
        size="left"
        position="right"
      >
        <AgentForm agent={selectedAgent} onSubmitSuccess={onCloseAgentForm} />
      </Drawer>
    </Container>
  )
}
