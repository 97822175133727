import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { IconEdit } from '@tabler/icons'
import { Distributor } from 'pages/distributors/types'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PaginationMeta } from 'types'

interface DistributorTableProps {
  distributors: Distributor[]
  pagination?: PaginationMeta
  onEditDistributor: (Distributor: Distributor) => void
  onPaginationChange: (page: number) => void
}

export function DistributorTable(props: DistributorTableProps) {
  const { distributors, pagination, onEditDistributor, onPaginationChange } =
    props

  const rows = useMemo(() => {
    return distributors.map((distributor) => (
      <tr key={distributor.id}>
        <td>
          <Text
            variant="link"
            component={Link}
            to={`/distributors/${distributor.id}`}
          >
            {distributor.name}
          </Text>
        </td>
        <td>{distributor.representative}</td>
        <td>{distributor.phone}</td>
        <td>{distributor.email}</td>
        <td>
          <Group>
            <ActionIcon
              color="green"
              variant="light"
              title="Cập nhật nhà phân phối"
              onClick={() => onEditDistributor(distributor)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [onEditDistributor, distributors])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Tên</th>
            <th>Tên người đại diện</th>
            <th>Số điện thoại</th>
            <th>Email</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} phân phối</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
