import {
  Autocomplete,
  Button,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import vnLocal from '@natpkg/vn-local'
import { IconDeviceFloppy, IconMail, IconPhone } from '@tabler/icons'
import dayjs from 'dayjs'
import {
  useCreateWarrantyCard,
  useGetCustomers,
  useGetFilms,
  useGetPackages,
} from 'pages/warranty-cards/hooks'
import React, { useCallback, useMemo, useState } from 'react'

interface WarrantyCardFormValue {
  customerId?: string
  fullname: string
  email: string
  phone: string
  address: string
  ward: string
  district: string
  city: string

  carNumber: string
  carProduceYear: number
  carBrand: string
  carModel: string

  package: string
  packagePeriod: number

  startDate: Date

  windscreen: string
  windscreenUsage?: number
  rearWindow: string
  rearWindowUsage?: number
  frontDoors: string
  frontDoorsUsage?: number
  backDoors: string
  backDoorsUsage?: number
  skyWindowType: string
  skyWindow: string
  skyWindowUsage?: number
}

const vnPhonenumber =
  /(([03+[2-9]|05+[6|8|9]|07+[0|6|7|8|9]|08+[1-9]|09+[1-4|6-9]]){3})+[0-9]{7}\b/g

const skyWindowTypes = ['Sunroof', 'Panoramic Sunroof']
interface WarrantyCardFormProps {
  onSubmitSuccess: () => void
  agentId?: string
  distributorId?: string
}

export function WarrantyCardForm(props: WarrantyCardFormProps) {
  const { onSubmitSuccess } = props

  const [error, setError] = useState<string | null>(null)

  const { mutate: submitCreateWarranty, isLoading: isCreateWarrantyLoading } =
    useCreateWarrantyCard()

  const form = useForm<WarrantyCardFormValue>({
    initialValues: {
      customerId: '',
      fullname: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      district: '',
      ward: '',
      carNumber: '',
      carProduceYear: 2015,
      carBrand: '',
      carModel: '',
      package: '',
      packagePeriod: 10,
      startDate: new Date(),
      windscreen: '',
      windscreenUsage: 0,
      rearWindow: '',
      rearWindowUsage: 0,
      frontDoors: '',
      frontDoorsUsage: 0,
      backDoors: '',
      backDoorsUsage: 0,
      skyWindowType: 'Sunroof',
      skyWindow: '',
      skyWindowUsage: 0,
    },
  })

  const selectedCity = form.values.city
  const selectedDistrict = form.values.district
  const selectedWard = form.values.ward

  const provinceSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    return provinces.map((item) => item.name)
  }, [])

  const districtsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const result = districts.map((item) => item.name)
    if (!result.includes(selectedDistrict)) {
      form.setFieldValue('district', '')
    }
    return result
  }, [selectedCity, selectedDistrict])

  const wardsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const district = districts.find((d) => d.name === selectedDistrict)
    if (!district) {
      return []
    }
    const wards = vnLocal.getWardsByDistrictCode(district.code) || []
    const result = wards.map((item) => item.name)
    if (!result.includes(selectedWard)) {
      form.setFieldValue('ward', '')
    }
    return result
  }, [selectedCity, selectedDistrict, selectedWard])

  const { data: packages } = useGetPackages()
  const { data: films } = useGetFilms()

  const handleSubmit = (values: WarrantyCardFormValue) => {
    const packageResult: { value: number } = (packages?.items || []).find(
      (p: { name: string; value: number }) => p.name === values.package
    )

    submitCreateWarranty(
      {
        ...values,
        packagePeriod: packageResult.value,
        agentId: props.agentId,
        distributorId: props.distributorId,
      },
      {
        onSuccess: () => onSubmitSuccess(),
        onError: () => setError('Không tạo được thẻ bảo hành'),
      }
    )
  }

  const { data: customers } = useGetCustomers({
    query: /^\S+@\S+\.\S+$/.test(form.values.email) ? form.values.email : '',
  })

  const onSelectCustomer = useCallback(
    ({ value }: { value: string }) => {
      const customer: {
        id: string
        email: string
        fullname: string
        phone: string
        address: string
        ward: string
        district: string
        city: string
      } = customers.items.find(
        (item: { email: string }) => item.email === value
      )

      if (customer) {
        form.setFieldValue('customerId', customer.id)
        form.setFieldValue('phone', customer.phone)
        form.setFieldValue('fullname', customer.fullname)
        form.setFieldValue('address', customer.address)
        form.setFieldValue('ward', customer.ward)
        form.setFieldValue('district', customer.district)
        form.setFieldValue('city', customer.city)
      }
    },
    [customers]
  )

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Title order={5}>Thông tin chủ thẻ</Title>
        <Group grow mt="xs" align="flex-start">
          <Autocomplete
            data={(customers?.items || []).map(
              (item: { email: string }) => item.email
            )}
            onItemSubmit={onSelectCustomer}
            required
            placeholder="Email chủ thẻ"
            label="Email"
            type="email"
            icon={<IconMail size={16} stroke={1.5} />}
            {...form.getInputProps('email')}
          />
          <TextInput
            required
            placeholder="Số điện thoại chủ thẻ"
            label="Số điện thoại"
            icon={<IconPhone size={16} stroke={1.5} />}
            pattern={vnPhonenumber}
            {...form.getInputProps('phone')}
          />
        </Group>
        <TextInput
          mt="md"
          required
          placeholder="Tên đầy đủ chủ thẻ"
          label="Họ và tên"
          {...form.getInputProps('fullname')}
        />
        <Stack mt="md">
          <TextInput
            required
            placeholder="Địa chỉ chủ thẻ"
            label="Địa chỉ"
            {...form.getInputProps('address')}
          />
          <Group grow>
            <Select
              required
              placeholder="Tỉnh/Thành phố"
              data={provinceSelectItems}
              searchable
              {...form.getInputProps('city')}
            />
            <Select
              required
              placeholder="Quận/Huyện"
              data={districtsSelectItems}
              searchable
              {...form.getInputProps('district')}
            />
            <Select
              required
              placeholder="Phường/Xã"
              data={wardsSelectItems}
              {...form.getInputProps('ward')}
            />
          </Group>
        </Stack>
        <Title order={5} mt="md">
          Thông tin xe
        </Title>
        <Group grow mt="xs" align="flex-start">
          <TextInput
            required
            placeholder="Biển số xe chủ thẻ"
            label="Biển số xe"
            {...form.getInputProps('carNumber')}
          />
          <TextInput
            required
            placeholder="Năm sản xuất xe chủ thẻ"
            label="Năm sản xuất xe"
            type="number"
            {...form.getInputProps('carProduceYear')}
          />
        </Group>
        <Group grow mt="md" align="flex-start">
          <TextInput
            required
            placeholder="Hiệu xe chủ thẻ"
            label="Hiệu xe"
            {...form.getInputProps('carBrand')}
          />
          <TextInput
            required
            placeholder="Loại xe chủ thẻ"
            label="Loại xe"
            pattern={vnPhonenumber}
            {...form.getInputProps('carModel')}
          />
        </Group>
        <Title order={5} mt="md">
          Thông tin dịch vụ
        </Title>
        <Group grow mt="xs" align="flex-start">
          <Select
            data={(packages?.items || []).map((p: { name: string }) => p.name)}
            required
            placeholder="Gói dịch vụ sử dụng"
            label="Gói dịch vụ"
            {...form.getInputProps('package')}
          />
          <DatePicker
            required
            placeholder="Ngày hiệu lực phiếu bảo hành"
            label="Ngày hiệu lực"
            minDate={new Date()}
            inputFormat="DD/MM/YYYY"
            maxDate={dayjs(new Date()).add(3, 'd').toDate()}
            clearable={false}
            {...form.getInputProps('startDate')}
          />
        </Group>
        <Group grow mt="md" align="flex-start">
          <Select
            data={(films?.items || []).map((p: { name: string }) => p.name)}
            required
            placeholder="Kính lái"
            label="Phim kính lái"
            {...form.getInputProps('windscreen')}
          />
          <Select
            data={(films?.items || []).map((p: { name: string }) => p.name)}
            required
            placeholder="Sườn trước"
            label="Phim sườn trước"
            {...form.getInputProps('frontDoors')}
          />
        </Group>
        <Group grow mt="md" align="flex-start">
          <Select
            data={(films?.items || []).map((p: { name: string }) => p.name)}
            required
            placeholder="Sườn sau"
            label="Phim sườn sau"
            {...form.getInputProps('backDoors')}
          />
          <Select
            data={(films?.items || []).map((p: { name: string }) => p.name)}
            required
            placeholder="Kính hậu"
            label="Phim kính hậu"
            {...form.getInputProps('rearWindow')}
          />
        </Group>
        <Group grow mt="md" align="flex-start">
          <Select
            data={skyWindowTypes}
            placeholder="Loại cửa sổ trời"
            label="Loại cửa sổ trời"
            {...form.getInputProps('skyWindowType')}
          />
          <Select
            data={(films?.items || []).map((p: { name: string }) => p.name)}
            placeholder="Cửa sổ trời"
            label="Phim cửa sổ trời"
            {...form.getInputProps('skyWindow')}
          />
        </Group>
        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}
        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateWarrantyLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
