import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { IconEdit, IconTrash, IconUserOff } from '@tabler/icons'
import { Role } from 'components'
import { User } from 'pages/users/types'
import React, { useMemo } from 'react'
import { PaginationMeta } from 'types'

interface UserTableProps {
  users: User[]
  pagination?: PaginationMeta
  onEditUser: (user: User) => void
  onPaginationChange: (page: number) => void
}

export function UserTable(props: UserTableProps) {
  const { users, pagination, onEditUser, onPaginationChange } = props

  const rows = useMemo(() => {
    return users.map((user) => (
      <tr key={user.id}>
        <td>{user.fullname}</td>
        <td>{user.username}</td>
        <td>
          <Role role={user.role} />
        </td>
        <td>
          <Group>
            <ActionIcon
              color="green"
              variant="light"
              title="Cập nhật người dùng"
              onClick={() => onEditUser(user)}
            >
              <IconEdit size={16} />
            </ActionIcon>
            <ActionIcon color="red" variant="light" title="Chặn người dùng">
              <IconUserOff size={16} />
            </ActionIcon>
            <ActionIcon color="red" variant="light" title="Xóa người dùng">
              <IconTrash size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [onEditUser, users])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Họ và tên</th>
            <th>Tên đăng nhập</th>
            <th style={{ width: 140 }}>Chức vụ</th>
            <th style={{ width: 140 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} người dùng</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
