import { useQuery } from 'react-query'
import api from 'services/api'

const getCustomer = async (id: string) => {
  const { data } = await api.get(`/customers/${id}`)

  return data
}

export function useGetCustomer(id: string) {
  return useQuery(['customer', id], () => getCustomer(id))
}
