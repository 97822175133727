import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { IconEdit } from '@tabler/icons'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PaginationMeta } from 'types'

import { Agent } from '../../pages/agents/types'

interface AgentTableProps {
  path: string
  agents: Agent[]
  pagination?: PaginationMeta
  onEditAgent: (Agent: Agent) => void
  onPaginationChange: (page: number) => void
}

export function AgentTable(props: AgentTableProps) {
  const { agents, path, pagination, onEditAgent, onPaginationChange } = props

  const rows = useMemo(() => {
    return agents.map((agent) => (
      <tr key={agent.id}>
        <td>
          <Text variant="link" component={Link} to={`${path}/${agent.id}`}>
            {agent.name}
          </Text>
        </td>
        <td>{agent.representative}</td>
        <td>{agent.phone}</td>
        <td>
          {agent?.address}, {agent?.ward}, {agent?.district}, {agent?.city}
        </td>
        <td>
          <Group>
            <ActionIcon
              color="green"
              variant="light"
              title="Cập nhật đại lý"
              onClick={() => onEditAgent(agent)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [agents])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Tên</th>
            <th>Tên người đại diện</th>
            <th>Số điện thoại</th>
            <th>Địa chỉ</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} đại lý</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
