import { TextInput } from '@mantine/core'
import { IconSearch } from '@tabler/icons'
import debounce from 'lodash/debounce'
import React, { ChangeEvent, memo, useCallback } from 'react'

interface CustomerFilterProps {
  value: string
  onChange: (value: string) => void
}

export const CustomerFilter = memo(function CustomerFilter(
  props: CustomerFilterProps
) {
  const { value, onChange } = props
  const onFilterValueChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }, 1000),
    []
  )

  return (
    <TextInput
      placeholder="Tìm kiếm theo tên..."
      mb="md"
      icon={<IconSearch size={14} stroke={1.5} />}
      defaultValue={value}
      onChange={onFilterValueChange}
    />
  )
})
