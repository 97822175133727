import { useQuery } from 'react-query'
import api from 'services/api'

const getDistributor = async (id: string) => {
  const { data } = await api.get(`/distributors/${id}`)

  return data
}

export function useGetDistributor(id: string) {
  return useQuery(['distributor', id], () => getDistributor(id))
}
