import { useMutation } from 'react-query'
import api from 'services/api'

import { NewProduct } from '../types'

interface CreateProduct {
  name: string
  type: string
  value: number
}

const createProduct = async (newProduct: NewProduct) => {
  const submitData: CreateProduct = {
    name: newProduct.name,
    type: newProduct.type,
    value: newProduct.value,
  }

  const { data: productData } = await api.post('/products', submitData)
  return productData
}

export function useCreateProduct() {
  return useMutation(createProduct)
}
