import { Stack, Table, Text } from '@mantine/core'
import React, { useMemo } from 'react'

import { Car } from '../types'

interface CarTableProps {
  cars: Car[]
}

export function CarTable(props: CarTableProps) {
  const { cars } = props

  const rows = useMemo(() => {
    return cars.map((car) => (
      <tr key={car.id}>
        <td>
          <Text>{car.numberPlate}</Text>
        </td>
        <td>{car.produceYear}</td>
        <td>{car.brand}</td>
        <td>{car.model}</td>
      </tr>
    ))
  }, [cars])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Biển số</th>
            <th>Năm</th>
            <th>Hãng</th>
            <th>Loại</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Stack>
  )
}
