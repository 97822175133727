import { useQuery } from 'react-query'
import api from 'services/api'

import { GetDistributorsParams } from '../types'

const getDistributors = async (params: GetDistributorsParams) => {
  const { data } = await api.get('/distributors', {
    params,
  })

  return data
}

export function useGetDistributors(distributorsParams: GetDistributorsParams) {
  return useQuery(
    ['agents-distributors', distributorsParams],
    () => getDistributors(distributorsParams),
    {
      enabled: !!distributorsParams.query,
    }
  )
}
