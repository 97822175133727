import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { IconEdit, IconTrash } from '@tabler/icons'
import { Product } from 'pages/products/types'
import React, { useMemo } from 'react'
import { PaginationMeta } from 'types'

const TYPE_TRANSLATIONS = {
  PACKAGE: 'Gói dịch vụ',
  FILM: 'Phim',
  WINDSCREEN_FILM: 'Phim kính lái',
  REARWINDOW_FILM: 'Phim kính lưng',
  FRONTDOOR_FILM: 'Phim kính sườn trước',
  BACKDOOR_FILM: 'Phim kính sườn sau',
  SKYWINDOW_FILM: 'Phim cửa sổ trời',
}

interface ProductTableProps {
  products: Product[]
  pagination?: PaginationMeta
  onEditProduct: (product: Product) => void
  onPaginationChange: (page: number) => void
}

export function ProductTable(props: ProductTableProps) {
  const { products, pagination, onEditProduct, onPaginationChange } = props

  const rows = useMemo(() => {
    return products.map((product) => (
      <tr key={product.id}>
        <td>{product.name}</td>
        <td>
          {TYPE_TRANSLATIONS[product.type as keyof typeof TYPE_TRANSLATIONS]}
        </td>
        <td>
          <Group>
            <ActionIcon
              color="green"
              variant="light"
              title="Cập nhật hạng mục"
              onClick={() => onEditProduct(product)}
            >
              <IconEdit size={16} />
            </ActionIcon>
            <ActionIcon color="red" variant="light" title="Xóa hạng mục">
              <IconTrash size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [onEditProduct, products])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Tên hạng mục</th>
            <th>Loại hạng mục</th>
            <th style={{ width: 140 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} hạng mục</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
