import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ProductFilter, ProductForm, ProductTable } from './components'
import { useGetProducts } from './hooks'
import { Product } from './types'

export function Products() {
  const [productFormOpened, setProductFormOpened] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetProducts({
    query: searchParams.get('query') || '',
    type: searchParams.get('type') || '',
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onCloseProductForm = useCallback(() => {
    setSelectedProduct(undefined)
    setProductFormOpened(false)
    refetch()
  }, [])

  const onEditProduct = useCallback((product: Product) => {
    setSelectedProduct(product)
    setProductFormOpened(true)
  }, [])

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Quản lý hạng mục</Title>
        <Button
          leftIcon={<IconPlus size={14} />}
          onClick={() => setProductFormOpened(true)}
        >
          Tạo hạng mục
        </Button>
      </Group>
      <ProductFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper shadow="xs" p="md" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <ProductTable
          products={data?.items || []}
          pagination={data?.meta}
          onEditProduct={onEditProduct}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        opened={productFormOpened}
        onClose={onCloseProductForm}
        title={
          <Title order={3}>
            {selectedProduct ? 'Cập nhật hạng mục' : 'Tạo hạng mục'}
          </Title>
        }
        padding="xl"
        size="xl"
        position="right"
      >
        <ProductForm
          product={selectedProduct}
          onSubmitSuccess={onCloseProductForm}
        />
      </Drawer>
    </Container>
  )
}
