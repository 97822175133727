import { useQuery } from 'react-query'
import api from 'services/api'

import { GetCustomerParams } from '../types'

const getCustomers = async (params: GetCustomerParams) => {
  const { data } = await api.get('/customers', {
    params,
  })

  return data
}

export function useGetCustomers(customersParams: GetCustomerParams) {
  return useQuery(['customers', customersParams], () =>
    getCustomers(customersParams)
  )
}
