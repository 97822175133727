import { useQuery } from 'react-query'
import api from 'services/api'

import { GetProductsParams } from '../types'

const getProducts = async (productsParams: GetProductsParams) => {
  const { data } = await api.get('/products', {
    params: productsParams,
  })

  return data
}

export function useGetProducts(productsParams: GetProductsParams) {
  return useQuery(['products', productsParams], () =>
    getProducts(productsParams)
  )
}
