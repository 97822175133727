import {
  Button,
  Container,
  createStyles,
  Image,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import image from './images/404.svg'

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 160,
    paddingBottom: 80,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}))

export function NotFound() {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const onGoBack = useCallback(() => {
    navigate('/customers', { replace: true })
  }, [])

  return (
    <Container className={classes.root}>
      <SimpleGrid
        spacing={80}
        cols={2}
        breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}
      >
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Có gì đó không đúng...</Title>
          <Text color="dimmed" size="lg">
            Trang bạn đang cố mở không tồn tại. Bạn có thể đã gõ sai địa chỉ
            hoặc trang đã được chuyển đến một URL khác.
          </Text>
          <Button
            variant="outline"
            size="md"
            mt="xl"
            className={classes.control}
            onClick={onGoBack}
          >
            Quay lại trang chính
          </Button>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  )
}
