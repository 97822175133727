import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  DistributorFilter,
  DistributorForm,
  DistributorTable,
} from './components'
import { useGetDistributors } from './hooks'
import { Distributor } from './types'

export function Distributors() {
  const [distributorFormOpened, setDistributorFormOpened] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState<Distributor>()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetDistributors({
    query: searchParams.get('query') || '',
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onCloseDistributorForm = useCallback(() => {
    setSelectedDistributor(undefined)
    setDistributorFormOpened(false)
    refetch()
  }, [])

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onEditDistributor = useCallback((distributor: Distributor) => {
    setSelectedDistributor(distributor)
    setDistributorFormOpened(true)
  }, [])

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Quản lý nhà phân phối</Title>
        <Button
          leftIcon={<IconPlus size={14} />}
          onClick={() => setDistributorFormOpened(true)}
        >
          Tạo phân phối
        </Button>
      </Group>
      <DistributorFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper shadow="xs" p="md" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <DistributorTable
          distributors={data?.items || []}
          pagination={data?.meta}
          onEditDistributor={onEditDistributor}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={distributorFormOpened}
        onClose={onCloseDistributorForm}
        title={
          <Title order={3}>
            {selectedDistributor
              ? 'Cập nhà nhật phân phối'
              : 'Tạo nhà phân phối'}
          </Title>
        }
        padding="xl"
        size="left"
        position="right"
      >
        <DistributorForm
          distributor={selectedDistributor}
          onSubmitSuccess={onCloseDistributorForm}
        />
      </Drawer>
    </Container>
  )
}
