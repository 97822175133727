import { useMutation } from 'react-query'
import api from 'services/api'

import { NewAgent } from '../types'

const createAgent = async (newAgent: NewAgent) => {
  const { data: distributorData } = await api.post('/agents', newAgent)

  return distributorData
}

export function useCreateAgent() {
  return useMutation(createAgent)
}
