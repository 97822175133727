import {
  Button,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import vnLocal from '@natpkg/vn-local'
import { IconDeviceFloppy, IconMail, IconPhone } from '@tabler/icons'
import {
  useCreateDistributor,
  useUpdateDistributor,
} from 'pages/distributors/hooks'
import { Distributor } from 'pages/distributors/types'
import React, { useMemo, useState } from 'react'

export interface DistributorFormProps {
  distributor?: Distributor
  onSubmitSuccess: () => void
}

interface DistributorFormValue {
  id?: string
  name: string
  representative: string
  address: string
  ward: string
  district: string
  city: string
  email: string
  phone: string
}

const usernameRegex = /^[a-z0-9_\.]+$/
const vnPhonenumber =
  /(([03+[2-9]|05+[6|8|9]|07+[0|6|7|8|9]|08+[1-9]|09+[1-4|6-9]]){3})+[0-9]{7}\b/g

export function DistributorForm(props: DistributorFormProps) {
  const { distributor, onSubmitSuccess } = props

  const {
    mutate: submitCreateDistributor,
    isLoading: isCreateDistributorLoading,
  } = useCreateDistributor()
  const {
    mutate: submitUpdateDistributor,
    isLoading: isUpdateDistributorLoading,
  } = useUpdateDistributor()

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      id: distributor?.id,
      name: distributor?.name || '',
      representative: distributor?.representative || '',
      address: distributor?.address || '',
      ward: distributor?.ward || '',
      district: distributor?.district || '',
      city: distributor?.city || '',
      email: distributor?.email || '',
      phone: distributor?.phone || '',
    },
  })

  const handleSubmit = (values: DistributorFormValue) => {
    values.id
      ? submitUpdateDistributor(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không cập nhật được nhà phân phối'),
        })
      : submitCreateDistributor(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không tạo được nhà phân phối'),
        })
  }

  const selectedCity = form.values.city
  const selectedDistrict = form.values.district
  const selectedWard = form.values.ward

  const provinceSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    return provinces.map((item) => item.name)
  }, [])

  const districtsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const result = districts.map((item) => item.name)
    if (!result.includes(selectedDistrict)) {
      form.setFieldValue('district', '')
    }
    return result
  }, [selectedCity, selectedDistrict])

  const wardsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const district = districts.find((d) => d.name === selectedDistrict)
    if (!district) {
      return []
    }
    const wards = vnLocal.getWardsByDistrictCode(district.code) || []
    const result = wards.map((item) => item.name)
    if (!result.includes(selectedWard)) {
      form.setFieldValue('ward', '')
    }
    return result
  }, [selectedCity, selectedDistrict, selectedWard])

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group grow align="flex-start">
          <TextInput
            required
            placeholder="Tên đầy đủ của nhà phân phối"
            label="Tên nhà phân phối"
            {...form.getInputProps('name')}
          />
          <TextInput
            required
            placeholder="Tên đầy đủ của người đại diện"
            label="Tên người đại diện"
            {...form.getInputProps('representative')}
          />
        </Group>

        <Group grow mt="md" align="flex-start">
          <TextInput
            required
            placeholder="Email của nhà phân phối"
            label="Email"
            type="email"
            icon={<IconMail size={16} stroke={1.5} />}
            {...form.getInputProps('email')}
          />
          <TextInput
            required
            placeholder="Số điện thoại của nhà phân phối"
            label="Số điện thoại"
            type="number"
            icon={<IconPhone size={16} stroke={1.5} />}
            pattern={vnPhonenumber}
            {...form.getInputProps('phone')}
          />
        </Group>

        <Stack mt="md">
          <TextInput
            required
            placeholder="Địa chỉ của nhà phân phối"
            label="Địa chỉ"
            {...form.getInputProps('address')}
          />
          <Group grow>
            <Select
              required
              placeholder="Tỉnh/Thành phố"
              data={provinceSelectItems}
              searchable
              {...form.getInputProps('city')}
            />
            <Select
              required
              placeholder="Quận/Huyện"
              data={districtsSelectItems}
              searchable
              {...form.getInputProps('district')}
            />
            <Select
              required
              placeholder="Phường/Xã"
              data={wardsSelectItems}
              {...form.getInputProps('ward')}
            />
          </Group>
        </Stack>

        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateDistributorLoading || isUpdateDistributorLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
