import { useQuery } from 'react-query'
import api from 'services/api'

const getAgent = async (id: string) => {
  const { data } = await api.get(`/agents/${id}`)

  return data
}

export function useGetAgent(id: string) {
  return useQuery(['agent', id], () => getAgent(id))
}
