import { useMutation } from 'react-query'
import api from 'services/api'

import { NewDistributor } from '../types'

const createDistributor = async (newDistributor: NewDistributor) => {
  const { data: distributorData } = await api.post(
    '/distributors',
    newDistributor
  )

  return distributorData
}

export function useCreateDistributor() {
  return useMutation(createDistributor)
}
