import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { UserForm } from 'pages/users/components'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CustomerFilter, CustomerForm, CustomerTable } from './components'
import { useGetCustomers } from './hooks'
import { Customer } from './types'

export function Customers() {
  const [userFormOpened, setUserFormOpened] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetCustomers({
    query: searchParams.get('query') || '',
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onCloseUserForm = useCallback(() => {
    setSelectedCustomer(undefined)
    setUserFormOpened(false)
    refetch()
  }, [])

  const onEditCustomer = useCallback((customer: Customer) => {
    setSelectedCustomer(customer)
    setUserFormOpened(true)
  }, [])

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Quản lý khách hàng</Title>
        <Button
          leftIcon={<IconPlus size={14} />}
          onClick={() => setUserFormOpened(true)}
        >
          Tạo khách hàng
        </Button>
      </Group>
      <CustomerFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper shadow="xs" p="md" style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <CustomerTable
          customers={data?.items || []}
          pagination={data?.meta}
          onEditCustomer={onEditCustomer}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={userFormOpened}
        onClose={onCloseUserForm}
        title={
          <Title order={3}>
            {selectedCustomer ? 'Cập nhật khách hàng' : 'Tạo khách hàng'}
          </Title>
        }
        padding="xl"
        size="left"
        position="right"
      >
        {selectedCustomer ? (
          <CustomerForm
            customer={selectedCustomer}
            onSubmitSuccess={onCloseUserForm}
          />
        ) : (
          <UserForm onSubmitSuccess={onCloseUserForm} role="CUSTOMER" />
        )}
      </Drawer>
    </Container>
  )
}
