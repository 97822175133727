import { ActionIcon, Group, Stack, Table, Text } from '@mantine/core'
import { IconEdit, IconTrash, IconUserOff } from '@tabler/icons'
import React, { useMemo } from 'react'

import { User } from '../../types'

interface UserTableProps {
  users: User[]
  onEditUser: (user: User) => void
}

export function UserTable(props: UserTableProps) {
  const { users, onEditUser } = props

  const rows = useMemo(() => {
    return users.map((user) => (
      <tr key={user.id}>
        <td>
          <Text>{user.fullname}</Text>
        </td>
        <td>{user.username}</td>
        <td>
          <td>
            <Group>
              <ActionIcon
                color="green"
                variant="light"
                title="Cập nhật người dùng"
                onClick={() => onEditUser(user)}
              >
                <IconEdit size={16} />
              </ActionIcon>
              <ActionIcon color="red" variant="light" title="Chặn người dùng">
                <IconUserOff size={16} />
              </ActionIcon>
              <ActionIcon color="red" variant="light" title="Xóa người dùng">
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          </td>
        </td>
      </tr>
    ))
  }, [users])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Họ và tên</th>
            <th>Tên đăng nhập</th>
            <th style={{ width: 160 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Stack>
  )
}
