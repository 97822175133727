import { useQuery } from 'react-query'
import api from 'services/api'

import { GetWarrantyCardsParams } from '../types'

const getWarrantyCards = async (params: GetWarrantyCardsParams) => {
  const { data } = await api.get('/warranties', {
    params,
  })

  return data
}

export function useGetWarrantyCards(
  warrantyCardsParams: GetWarrantyCardsParams
) {
  return useQuery(['warranty-cards', warrantyCardsParams], () =>
    getWarrantyCards(warrantyCardsParams)
  )
}
