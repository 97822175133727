import { useMutation } from 'react-query'
import api from 'services/api'

import { NewUser, ROLE } from '../types'

interface CreateUser {
  fullname: string
  username: string
  password: string
  role: ROLE
  customer?: {
    address: string
    ward: string
    district: string
    city: string
    email: string
    phone: string
    birthday?: Date
  }
  distributorId?: string
  agentId?: string
}

const createUser = async (newUser: NewUser) => {
  console.log('newUser', newUser)
  const submitData: CreateUser = {
    fullname: newUser.fullname,
    username: newUser.username,
    password: newUser.password,
    role: newUser.role,
    distributorId: newUser.distributorId,
    agentId: newUser.agentId,
  }
  if (newUser.role === 'CUSTOMER') {
    submitData.customer = {
      email: newUser.email || '',
      birthday: newUser.birthday,
      phone: newUser.phone || '',
      address: newUser.address || '',
      ward: newUser.ward || '',
      district: newUser.district || '',
      city: newUser.city || '',
    }
  }

  const { data: userData } = await api.post('/users', submitData)

  return userData
}

export function useCreateUser() {
  return useMutation(createUser)
}
