import { useQuery } from 'react-query'
import api from 'services/api'

const getUsers = async (distributorId: string) => {
  const { data } = await api.get('/users', {
    params: {
      distributorId,
    },
  })

  return data
}

export function useGetUsers(distributorId: string) {
  return useQuery(['distributor-users', distributorId], () =>
    getUsers(distributorId)
  )
}
