import {
  Code,
  createStyles,
  Group,
  Navbar,
  Title,
  UnstyledButton,
} from '@mantine/core'
import { IconLogout, TablerIcon } from '@tabler/icons'
import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({
          variant: 'light',
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: 'light',
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  }
})

interface NavItem {
  link: string
  label: string
  icon: TablerIcon
}

interface NavbarSimpleProps {
  links: NavItem[]
  onLogout: () => void
}

export const NavbarSimple = memo(function NavbarSimple(
  props: NavbarSimpleProps
) {
  const { links, onLogout } = props
  const { classes, cx } = useStyles()

  const linkComponents = links.map((item) => (
    <NavLink
      className={({ isActive }) => {
        return cx(classes.link, {
          [classes.linkActive]: isActive,
        })
      }}
      to={item.link}
      key={item.label}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  ))

  return (
    <Navbar width={{ sm: 300 }} p="md">
      <Navbar.Section grow>
        <Group className={classes.header} position="apart">
          <Title order={2}>
            STEK
            <Code sx={{ fontWeight: 700 }}>Vietnam</Code>
          </Title>
          <Code sx={{ fontWeight: 700 }}>v0.1.4</Code>
        </Group>
        {linkComponents}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <UnstyledButton className={classes.link} onClick={onLogout}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Đăng xuất</span>
        </UnstyledButton>
      </Navbar.Section>
    </Navbar>
  )
})
