import {
  Button,
  Card,
  Container,
  createStyles,
  Drawer,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { AgentForm, UserForm } from 'components'
import { AgentTable } from 'components/agent-table/agent-table'
import { WarrantyCards } from 'pages'
import { Agent } from 'pages/agents/types'
import { User } from 'pages/users/types'
import React, { useCallback, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { UserTable } from './components'
import { useGetAgents, useGetDistributor, useGetUsers } from './hooks'

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${theme.colors.gray[1]}`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}))

export function Distributor() {
  const { classes } = useStyles()

  const [userFormOpened, setUserFormOpened] = useState(false)
  const [agentFormOpened, setAgentFormOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const [selectedAgent, setSelectedAgent] = useState<Agent>()

  const { slug } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: distributor, isLoading } = useGetDistributor(slug as string)

  const {
    data: agents,
    isLoading: isAgentsLoading,
    refetch: refetchAgent,
  } = useGetAgents(slug as string)

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch: refetchUser,
  } = useGetUsers(slug as string)

  const onEditAgent = useCallback((distributor: Agent) => {
    setSelectedAgent(distributor)
    setAgentFormOpened(true)
  }, [])

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onCloseForm = useCallback(() => {
    setSelectedUser(undefined)
    setUserFormOpened(false)
    setSelectedAgent(undefined)
    setAgentFormOpened(false)
    refetchUser()
    refetchAgent()
  }, [])

  const onEditUser = useCallback((user: any) => {
    setSelectedUser(user)
    setUserFormOpened(true)
  }, [])

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Thông tin nhà phân phối</Title>
      </Group>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay
          visible={isLoading || isAgentsLoading || isUsersLoading}
        />

        <SimpleGrid
          cols={2}
          spacing="xl"
          breakpoints={[{ maxWidth: 'md', cols: 1 }]}
          mb="xl"
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart" align="top">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Thông tin nhà phân phối:
              </Text>
            </Group>
            <Group position="apart" mt="sm">
              <Text weight={600}>Tên:</Text>
              <Text>{distributor?.name}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Email:</Text>
              <Text>{distributor?.email}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Người đại diện:</Text>
              <Text>{distributor?.representative}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Số điện thoại:</Text>
              <Text>{distributor?.phone}</Text>
            </Group>
            <Group position="apart" spacing={0}>
              <Text weight={600}>Địa chỉ:</Text>
              <Text>
                {distributor?.address}, {distributor?.ward},{' '}
                {distributor?.district}, {distributor?.city}
              </Text>
            </Group>
          </Card>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Danh sách tài khoản:
              </Text>
              <Button
                size="xs"
                leftIcon={<IconPlus size={14} />}
                onClick={() => setUserFormOpened(true)}
              >
                Tạo tài khoản
              </Button>
            </Group>
            <UserTable users={users?.items || []} onEditUser={onEditUser} />
          </Card>
        </SimpleGrid>
        <Card shadow="md" radius="md" className={classes.card} p="xl" mb="xl">
          <Group position="apart">
            <Text size="lg" weight={500} className={classes.cardTitle}>
              Danh sách đại lý:
            </Text>
            <Button
              size="xs"
              leftIcon={<IconPlus size={14} />}
              onClick={() => setAgentFormOpened(true)}
            >
              Tạo đại lý
            </Button>
          </Group>
          <AgentTable
            path="agents"
            agents={agents?.items || []}
            pagination={agents?.meta}
            onEditAgent={onEditAgent}
            onPaginationChange={onPaginationChange}
          />
        </Card>
      </div>
      <Card shadow="md" radius="md" className={classes.card} p="xl">
        <WarrantyCards
          title="Danh sách thẻ bảo hành:"
          type="plugin"
          distributorId={slug}
        />
      </Card>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={userFormOpened || agentFormOpened}
        onClose={onCloseForm}
        title={
          (userFormOpened && (
            <Title order={3}>
              {selectedUser ? 'Cập nhật tài khoản' : 'Tạo tài khoản'}
            </Title>
          )) ||
          (agentFormOpened && (
            <Title order={3}>
              {selectedAgent ? 'Cập nhật đại lý' : 'Tạo đại lý'}
            </Title>
          ))
        }
        padding="xl"
        size="left"
        position="right"
      >
        {userFormOpened && (
          <UserForm
            user={selectedUser}
            role="DISTRIBUTOR"
            distributorId={slug}
            onSubmitSuccess={onCloseForm}
          />
        )}
        {agentFormOpened && (
          <AgentForm
            agent={selectedAgent}
            distributorId={slug}
            onSubmitSuccess={onCloseForm}
          />
        )}
      </Drawer>
    </Container>
  )
}
