import { useQuery } from 'react-query'
import api from 'services/api'

const getFilms = async () => {
  const { data } = await api.get('/products', {
    params: { type: 'FILM', limit: 100 },
  })

  return data
}

export function useGetFilms() {
  return useQuery(['warranty-films'], () => getFilms())
}
