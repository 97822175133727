import {
  Card,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core'
import { WarrantyCardTable } from 'components/warranty-table/warranty-table'
import React, { useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { CarTable } from './components/car-table'
import { useGetCars, useGetCustomer, useGetWarrantyCards } from './hooks'

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${theme.colors.gray[1]}`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}))

export function Customer() {
  const { classes } = useStyles()

  const { slug } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: customer, isLoading } = useGetCustomer(slug as string)

  const { data: warrantyCards } = useGetWarrantyCards({
    customerId: slug as string,
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const { data: cars, isLoading: isCarsLoading } = useGetCars({
    customerId: slug as string,
    limit: 10,
  })

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Thông tin khách hàng</Title>
      </Group>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading || isCarsLoading} />
        <SimpleGrid
          cols={2}
          spacing="xl"
          breakpoints={[{ maxWidth: 'md', cols: 1 }]}
          mb="xl"
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart" align="top">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Thông tin khách hàng:
              </Text>
            </Group>
            <Group position="apart" mt="sm">
              <Text weight={600}>Họ và tên:</Text>
              <Text>{customer?.fullname}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Email:</Text>
              <Text>{customer?.email}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Số điện thoại:</Text>
              <Text>{customer?.phone}</Text>
            </Group>
            <Group position="apart" spacing={0}>
              <Text weight={600}>Địa chỉ:</Text>
              <Text>
                {customer?.address}, {customer?.ward}, {customer?.district},{' '}
                {customer?.city}
              </Text>
            </Group>
          </Card>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Text size="lg" weight={500} className={classes.cardTitle}>
              Thông tin tài khoản:
            </Text>
            <Group position="apart" mt="sm">
              <Text weight={600}>Tên đăng nhập:</Text>
              <Text>{customer?.user?.username}</Text>
            </Group>
          </Card>
        </SimpleGrid>
        <Card shadow="md" radius="md" className={classes.card} p="xl" mb="xl">
          <Text size="lg" weight={500} className={classes.cardTitle}>
            Danh sách xe:
          </Text>
          <CarTable cars={cars?.items || []} />
        </Card>
      </div>
      <Card shadow="md" radius="md" className={classes.card} p="xl">
        <Text size="lg" weight={500} className={classes.cardTitle}>
          Danh sách thẻ bảo hành:
        </Text>
        <WarrantyCardTable
          path={`warranty-cards`}
          warrantyCards={warrantyCards?.items || []}
          pagination={warrantyCards?.meta}
          onPaginationChange={onPaginationChange}
        />
      </Card>
    </Container>
  )
}
