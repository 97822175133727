import {
  Button,
  Group,
  Paper,
  PasswordInput,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import vnLocal from '@natpkg/vn-local'
import {
  IconDeviceFloppy,
  IconLock,
  IconMail,
  IconPhone,
  IconUser,
} from '@tabler/icons'
import dayjs from 'dayjs'
import { useCreateUser, useUpdateUser } from 'pages/users/hooks'
import { ROLE, User } from 'pages/users/types'
import React, { useMemo, useState } from 'react'

export interface UserFormProps {
  user?: User
  onSubmitSuccess: () => void
  role?: ROLE
}

interface UserFormValue {
  id: string
  fullname: string
  username: string
  password: string
  confirmPassword: string
  role: ROLE

  // location of customer, distributor, agent
  address: string
  ward: string
  district: string
  city: string

  // customer, distributor, agent information
  email: string
  phone: string

  // customer information
  customerId: string
  birthday?: Date

  // distributor, agent information
  agentId: string
  distributorId: string
  name: string
  representative: string
}

const usernameRegex = /^[a-z0-9_\.]+$/
const vnPhonenumber =
  /(([03+[2-9]|05+[6|8|9]|07+[0|6|7|8|9]|08+[1-9]|09+[1-4|6-9]]){3})+[0-9]{7}\b/g

export function UserForm(props: UserFormProps) {
  const { user, onSubmitSuccess, role: roleType } = props

  const { mutate: submitCreateUser, isLoading: isCreateUserLoading } =
    useCreateUser()
  const { mutate: submitUpdateUser, isLoading: isUpdateUserLoading } =
    useUpdateUser()

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      id: user?.id || '',
      fullname: user?.fullname || '',
      username: user?.username || '',
      password: '',
      confirmPassword: '',
      role: user?.role || roleType || 'CUSTOMER',

      // location of customer, distributor, agent
      address: user?.customer?.address || '',
      ward: user?.customer?.ward || '',
      district: user?.customer?.district || '',
      city: user?.customer?.city || '',

      // customer, distributor, agent information
      email: user?.customer?.email || '',
      phone: user?.customer?.phone || '',

      // customer information
      customerId: user?.customer?.id || '',
      birthday: user?.customer?.birthday
        ? dayjs(user?.customer?.birthday, 'yyyy-mm-dd').toDate()
        : undefined,

      // distributor, agent information
      agentId: '',
      distributorId: '',
      name: '',
      representative: '',
    },
    validate: {
      username: (value: string) =>
        value.trim().length < 5
          ? 'Quá ngắn'
          : !usernameRegex.exec(value)
          ? 'Tên đăng nhập không hợp lệ'
          : null,
      confirmPassword: (value, values) =>
        value !== values.password ? 'Mật khẩu không đúng' : null,
    },
  })

  const handleSubmit = (values: UserFormValue) => {
    values.id
      ? submitUpdateUser(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không cập nhật được người dùng'),
        })
      : submitCreateUser(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không tạo được người dùng'),
        })
  }

  const selectedRole = form.values.role
  const selectedCity = form.values.city
  const selectedDistrict = form.values.district
  const selectedWard = form.values.ward

  const provinceSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    return provinces.map((item) => item.name)
  }, [])

  const districtsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const result = districts.map((item) => item.name)
    if (!result.includes(selectedDistrict)) {
      form.setFieldValue('district', '')
    }
    return result
  }, [selectedCity, selectedDistrict])

  const wardsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const district = districts.find((d) => d.name === selectedDistrict)
    if (!district) {
      return []
    }
    const wards = vnLocal.getWardsByDistrictCode(district.code) || []
    const result = wards.map((item) => item.name)
    if (!result.includes(selectedWard)) {
      form.setFieldValue('ward', '')
    }
    return result
  }, [selectedCity, selectedDistrict, selectedWard])

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group grow align="flex-start">
          <TextInput
            required
            placeholder="Tên đầy đủ của người dùng"
            label="Họ và tên"
            {...form.getInputProps('fullname')}
          />
          {selectedRole === 'CUSTOMER' && (
            <DatePicker
              required
              placeholder="Ngày sinh nhật của người dùng"
              label="Ngày sinh"
              withAsterisk
              inputFormat="DD/MM/YYYY"
              {...form.getInputProps('birthday')}
            />
          )}
        </Group>
        {selectedRole === 'CUSTOMER' && (
          <>
            <Group grow mt="md" align="flex-start">
              <TextInput
                required
                placeholder="Email của người dùng"
                label="Email"
                type="email"
                icon={<IconMail size={16} stroke={1.5} />}
                {...form.getInputProps('email')}
              />
              <TextInput
                required
                placeholder="Số điện thoại của người dùng"
                label="Số điện thoại"
                type="number"
                icon={<IconPhone size={16} stroke={1.5} />}
                pattern={vnPhonenumber}
                {...form.getInputProps('phone')}
              />
            </Group>
            <Stack mt="md">
              <TextInput
                required
                placeholder="Địa chỉ của người dùng"
                label="Địa chỉ"
                {...form.getInputProps('address')}
              />
              <Group grow>
                <Select
                  required
                  placeholder="Tỉnh/Thành phố"
                  data={provinceSelectItems}
                  searchable
                  {...form.getInputProps('city')}
                />
                <Select
                  required
                  placeholder="Quận/Huyện"
                  data={districtsSelectItems}
                  searchable
                  {...form.getInputProps('district')}
                />
                <Select
                  required
                  placeholder="Phường/Xã"
                  data={wardsSelectItems}
                  {...form.getInputProps('ward')}
                />
              </Group>
            </Stack>
          </>
        )}

        <Group grow mt="md" align="flex-start">
          <TextInput
            required
            placeholder="Tên đăng nhập của người dùng"
            label="Tên đăng nhập"
            icon={<IconUser size={16} stroke={1.5} />}
            {...form.getInputProps('username')}
            disabled={!!user}
          />
          {!roleType && (
            <Select
              label="Chức vụ"
              placeholder="Chọn chức vụ"
              data={[
                { value: 'ADMIN', label: 'Administrator' },
                { value: 'DISTRIBUTOR', label: 'Nhà phân phối' },
                { value: 'AGENT', label: 'Đại lý' },
                { value: 'CUSTOMER', label: 'Khách hàng' },
              ]}
              {...form.getInputProps('role')}
              disabled={!!user}
            />
          )}
        </Group>
        <PasswordInput
          mt="md"
          required={!user}
          placeholder="Mật khẩu"
          label="Mật khẩu"
          icon={<IconLock size={16} stroke={1.5} />}
          {...form.getInputProps('password')}
        />

        <PasswordInput
          mt="md"
          required={!user}
          label="Xác nhận mật khẩu"
          placeholder="Xác nhận mật khẩu"
          icon={<IconLock size={16} stroke={1.5} />}
          {...form.getInputProps('confirmPassword')}
        />

        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateUserLoading || isUpdateUserLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
