import { Button, Group, Paper, Select, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconDeviceFloppy } from '@tabler/icons'
import { useCreateProduct, useUpdateProduct } from 'pages/products/hooks'
import { Product } from 'pages/products/types'
import React, { useState } from 'react'

export interface UserFormProps {
  product?: Product
  onSubmitSuccess: () => void
}

interface ProductFormValue {
  id: string
  name: string
  type: string
  value: number
}

const TYPES = [
  { value: 'PACKAGE', label: 'Gói dịch vụ' },
  { value: 'FILM', label: 'Phim' },
  // { value: 'REARWINDOW_FILM', label: 'Phim kính lưng' },
  // { value: 'FRONTDOOR_FILM', label: 'Phim kính sườn trước' },
  // { value: 'BACKDOOR_FILM', label: 'Phim kính sườn sau' },
  // { value: 'SKYWINDOW_FILM', label: 'Phim cửa sổ trời' },
]

export function ProductForm(props: UserFormProps) {
  const { product, onSubmitSuccess } = props

  const { mutate: submitCreateProduct, isLoading: isCreateProductLoading } =
    useCreateProduct()
  const { mutate: submitUpdateProduct, isLoading: isUpdateProductLoading } =
    useUpdateProduct()

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      id: product?.id || '',
      name: product?.name || '',
      type: product?.type || 'PACKAGE',
      value: product?.value || 0,
    },
  })

  const handleSubmit = (values: ProductFormValue) => {
    values.id
      ? submitUpdateProduct(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không cập nhật được hạng mục'),
        })
      : submitCreateProduct(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không tạo được hạng mục'),
        })
  }

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Select
          label="Loại hạng mục"
          placeholder="Chọn loại hạng mục"
          data={TYPES}
          {...form.getInputProps('type')}
        />
        <Group grow mt="md" align="flex-start">
          <TextInput
            required
            placeholder="Tên hạng mục"
            label="Tên hạng mục"
            {...form.getInputProps('name')}
          />
          {form.values.type === 'PACKAGE' && (
            <TextInput
              required
              placeholder="Giá trị sử dụng"
              label="Giá trị"
              {...form.getInputProps('value')}
            />
          )}
        </Group>
        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateProductLoading || isUpdateProductLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
