import { Badge } from '@mantine/core'
import React, { memo } from 'react'

type ROLE = 'ADMIN' | 'DISTRIBUTOR' | 'AGENT' | 'CUSTOMER'
interface RoleProps {
  role: ROLE
}

export const Role = memo(function Role(props: RoleProps) {
  const { role } = props
  switch (role) {
    case 'ADMIN':
      return (
        <Badge variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
          Administrator
        </Badge>
      )
    case 'DISTRIBUTOR':
      return (
        <Badge
          variant="gradient"
          gradient={{ from: 'teal', to: 'blue', deg: 60 }}
        >
          Nhà phân phối
        </Badge>
      )
    case 'AGENT':
      return (
        <Badge
          variant="gradient"
          gradient={{ from: 'teal', to: 'blue', deg: 60 }}
        >
          Đại lý
        </Badge>
      )
    case 'CUSTOMER':
      return (
        <Badge
          variant="gradient"
          gradient={{ from: 'teal', to: 'lime', deg: 105 }}
        >
          Khách hàng
        </Badge>
      )
    default:
      return (
        <Badge
          variant="gradient"
          gradient={{ from: 'black', to: 'grey', deg: 105 }}
        >
          Khác
        </Badge>
      )
  }
})
