import { useQuery } from 'react-query'
import api from 'services/api'

import { GetDistributorParams } from '../types'

const getDistributors = async (params: GetDistributorParams) => {
  const { data } = await api.get('/distributors', {
    params,
  })

  return data
}

export function useGetDistributors(distributorsParams: GetDistributorParams) {
  return useQuery(['distributors', distributorsParams], () =>
    getDistributors(distributorsParams)
  )
}
