import {TextInput} from '@mantine/core'
import {IconSearch} from '@tabler/icons'
import debounce from 'lodash/debounce'
import {ChangeEvent, memo, useCallback} from 'react'

interface ProductFilterProps {
  value: string
  onChange: (value: string) => void
}

export const ProductFilter = memo(function ProductFilter(props: ProductFilterProps) {
  const {value, onChange} = props
  const onFilterValueChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }, 1000),
    []
  )

  return (
    <TextInput
      placeholder="Tìm kiếm theo tên sản phẩm..."
      mb="md"
      icon={<IconSearch size={14} stroke={1.5} />}
      defaultValue={value}
      onChange={onFilterValueChange}
    />
  )
})