import { useMutation } from 'react-query'
import api from 'services/api'

import { UpdateAgent } from '../types'

const updateAgent = async (updateAgent: UpdateAgent) => {
  await api.patch(`/agents/${updateAgent.id}`, updateAgent)
}

export function useUpdateAgent() {
  return useMutation(updateAgent)
}
