import { useMutation } from 'react-query'
import api from 'services/api'

import { NewCustomer } from '../types'

const createCustomer = async (newCustomer: NewCustomer) => {
  const { data: customerData } = await api.post('/customers', newCustomer)

  return customerData
}

export function useCreateCustomer() {
  return useMutation(createCustomer)
}
