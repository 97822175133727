import { useQuery } from 'react-query'
import api from 'services/api'

const getPackages = async () => {
  const { data } = await api.get('/products', {
    params: { type: 'PACKAGE', limit: 10 },
  })

  return data
}

export function useGetPackages() {
  return useQuery(['warranty-packages'], () => getPackages())
}
