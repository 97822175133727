import { useQuery } from 'react-query'
import api from 'services/api'

import { GetCustomersParams } from '../types'

const getCustomers = async (params: GetCustomersParams) => {
  const { data } = await api.get('/customers', {
    params,
  })

  return data
}

export function useGetCustomers(customersParams: GetCustomersParams) {
  return useQuery(
    ['warranty-customers', customersParams],
    () => getCustomers(customersParams),
    {
      enabled: !!customersParams.query,
    }
  )
}
