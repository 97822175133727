import { Layout } from 'components'
import { RequireAuth } from 'components/require-auth/require-auth'
import {
  Agent,
  Agents,
  Customer,
  Customers,
  Distributor,
  Distributors,
  Products,
  Signin,
  Users,
  WarrantyCard,
  WarrantyCards,
} from 'pages'
import { AuthProvider, useAuthContext } from 'providers'
import React, { useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

function AppRoutes() {
  const { user } = useAuthContext()

  const routes = useMemo(() => {
    if (user?.role === 'ADMIN') {
      return (
        <>
          <Route
            path="/users"
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug"
            element={
              <RequireAuth>
                <Customer />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards"
            element={
              <RequireAuth>
                <WarrantyCards />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/products"
            element={
              <RequireAuth>
                <Products />
              </RequireAuth>
            }
          />
          <Route
            path="/agents"
            element={
              <RequireAuth>
                <Agents />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/:slug"
            element={
              <RequireAuth>
                <Agent />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/:slug/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/distributors"
            element={
              <RequireAuth>
                <Distributors />
              </RequireAuth>
            }
          />
          <Route
            path="/distributors/:slug"
            element={
              <RequireAuth>
                <Distributor />
              </RequireAuth>
            }
          />
          <Route
            path="/distributors/:slug/agents/:slug"
            element={
              <RequireAuth>
                <Agent />
              </RequireAuth>
            }
          />
        </>
      )
    }
    if (user?.role === 'DISTRIBUTOR') {
      return (
        <>
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug"
            element={
              <RequireAuth>
                <Customer />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards"
            element={
              <RequireAuth>
                <WarrantyCards />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/agents"
            element={
              <RequireAuth>
                <Agents />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/:slug"
            element={
              <RequireAuth>
                <Agent />
              </RequireAuth>
            }
          />
          <Route
            path="/agents/:slug/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
        </>
      )
    }
    if (user?.role === 'AGENT') {
      return (
        <>
          <Route
            path="/customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug"
            element={
              <RequireAuth>
                <Customer />
              </RequireAuth>
            }
          />
          <Route
            path="/customers/:slug/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards"
            element={
              <RequireAuth>
                <WarrantyCards />
              </RequireAuth>
            }
          />
          <Route
            path="/warranty-cards/:slug"
            element={
              <RequireAuth>
                <WarrantyCard />
              </RequireAuth>
            }
          />
        </>
      )
    }
  }, [user])

  return (
    <Routes>
      <Route element={<Layout />}>{routes}</Route>
      <Route element={<Signin />} path="/signin" />
      <Route element={<Navigate to="/signin" />} path="" />
      {/* <Route element={<NotFound />} path="*" /> */}
    </Routes>
  )
}

function App() {
  return (
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  )
}

export default App
