import {
  Button,
  createStyles,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useAuthContext } from 'providers'
import { AuthUser } from 'providers/auth/types'
import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useLogin from './hooks/use-login'
import { BasicLogin } from './types'

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)',
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    minHeight: '100vh',
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export function Signin() {
  const { classes } = useStyles()

  const { mutate, isLoading, isError } = useLogin()
  const { setCurrentUser } = useAuthContext()

  const navigate = useNavigate()
  const location = useLocation()

  const from = (location.state as any)?.from?.pathname || '/customers'

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
  })

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      navigate(from, { replace: true })
    }
  }, [])

  const handleSubmit = useCallback((value: BasicLogin) => {
    mutate(value, {
      onSuccess: (user: AuthUser) => {
        setCurrentUser(user)
        navigate(from, { replace: true })
      },
    })
  }, [])

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title
          order={2}
          className={classes.title}
          align="center"
          mt="md"
          mb={50}
        >
          Chào mừng bạn quay trở lại Stek Vietnam!
        </Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            required
            label="Tên tài khoản"
            placeholder="Tên tài khoản của bạn"
            size="md"
            {...form.getInputProps('username')}
          />
          <PasswordInput
            required
            label="Mật khẩu"
            placeholder="Mật khẩu của bạn"
            mt="md"
            size="md"
            {...form.getInputProps('password')}
          />

          {isError && (
            <Text color="red" size="sm" mt="sm">
              Tên người dùng hoặc mật khẩu không chính xác
            </Text>
          )}

          <Button type="submit" fullWidth mt="xl" size="md" loading={isLoading}>
            Đăng nhập
          </Button>
        </form>
      </Paper>
    </div>
  )
}
