import {
  Button,
  Container,
  Drawer,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core'
import { IconPlus } from '@tabler/icons'
import { WarrantyCardTable } from 'components/warranty-table/warranty-table'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { WarrantyCardFilter, WarrantyCardForm } from './components'
import { useGetWarrantyCards } from './hooks'

interface WarrantyCardsProps {
  title?: string
  type?: 'plugin'
  agentId?: string
  distributorId?: string
}

export function WarrantyCards(props: WarrantyCardsProps) {
  const [warrantyCardFormOpened, setWarrantyCardFormOpened] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading, refetch } = useGetWarrantyCards({
    query: searchParams.get('query') || '',
    agentId: props.agentId,
    distributorId: props.distributorId,
    page: +(searchParams.get('page') || 1),
    limit: 10,
  })

  const onFilterChange = useCallback(
    (query: string) => {
      searchParams.set('query', query)
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onPaginationChange = useCallback(
    (page: number) => {
      searchParams.set('page', page.toString())
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  const onCloseWarrantyForm = useCallback(() => {
    setWarrantyCardFormOpened(false)
    refetch()
  }, [])

  return (
    <Container fluid p={props.type === 'plugin' ? 0 : 'md'}>
      <Group position="apart" align="center" mb="md">
        {props.type === 'plugin' ? (
          <Text size="lg" weight={500}>
            {props.title || 'Quản lý thẻ bảo hành'}
          </Text>
        ) : (
          <Title>{props.title || 'Quản lý thẻ bảo hành'}</Title>
        )}

        <Button
          onClick={() => setWarrantyCardFormOpened(true)}
          leftIcon={<IconPlus size={14} />}
          size={props.type === 'plugin' ? 'xs' : 'sm'}
        >
          Tạo thẻ bảo hành
        </Button>
      </Group>
      <WarrantyCardFilter
        value={searchParams.get('query') || ''}
        onChange={onFilterChange}
      />
      <Paper
        shadow={props.type === 'plugin' ? '' : 'xs'}
        p={props.type === 'plugin' ? 0 : 'md'}
        style={{ position: 'relative' }}
      >
        <LoadingOverlay visible={isLoading} />
        <WarrantyCardTable
          path="/warranty-cards"
          warrantyCards={data?.items || []}
          pagination={data?.meta}
          onPaginationChange={onPaginationChange}
        />
      </Paper>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={warrantyCardFormOpened}
        onClose={onCloseWarrantyForm}
        title={
          <Title px="xl" pt="xl" order={3}>
            Tạo thẻ bảo hành
          </Title>
        }
        size="left"
        position="right"
      >
        <ScrollArea p="xl" style={{ height: 'calc(100vh - 70px)' }}>
          <WarrantyCardForm
            agentId={props.agentId}
            distributorId={props.distributorId}
            onSubmitSuccess={onCloseWarrantyForm}
          />
        </ScrollArea>
      </Drawer>
    </Container>
  )
}
