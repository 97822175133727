import { useMutation } from 'react-query'
import api from 'services/api'

import { BasicLogin, LoginResponse } from '../types'

const basicLogin = async (loginParams: BasicLogin) => {
  const { data: loginData } = await api.post<LoginResponse>(
    '/login',
    loginParams
  )
  localStorage.setItem('token', loginData.accessToken)

  const { data: userInfo } = await api.get('users/profile')

  return userInfo
}

export default function useLogin() {
  return useMutation(basicLogin)
}
