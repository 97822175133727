import { useQuery } from 'react-query';
import api from 'services/api';

const getUsers = async (agentId: string) => {
  const { data } = await api.get('/users', {
    params: {
      agentId,
    },
  });

  return data;
};

export function useGetUsers(
  agentId: string,
) {
  return useQuery(['agent-users', agentId], () => getUsers(agentId));
}
