import { useQuery } from 'react-query'
import api from 'services/api'

import { GetAgentParams } from '../types'

const getAgents = async (params: GetAgentParams) => {
  const { data } = await api.get('/agents', {
    params,
  })

  return data
}

export function useGetAgents(agentsParams: GetAgentParams) {
  return useQuery(['agents', agentsParams], () => getAgents(agentsParams))
}
