import { useMutation } from 'react-query';
import api from 'services/api';

const updateAgentImage = async (updateInfo: { id: string; file: File }) => {
  const formData = new FormData();
  formData.append('file', updateInfo.file);
  const { data } = await api.post(`/agents/${updateInfo.id}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data as {
    id: string;
    imageUrl: string;
  };
};

export function useUpdateAgentImage(options: {
  onSuccess: () => void;
  onError: () => void;
}) {
  return useMutation(updateAgentImage, options);
}
