import {
  Button,
  Card,
  Container,
  createStyles,
  Drawer,
  FileButton,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons';
import { UserForm } from 'components';
import { User } from 'pages/users/types';
import { WarrantyCards } from 'pages/warranty-cards/warranty-cards';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserTable } from './components';
import { useGetAgent, useGetUsers, useUpdateAgentImage } from './hooks';

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${theme.colors.gray[1]}`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export function Agent() {
  const { classes } = useStyles();

  const [userFormOpened, setUserFormOpened] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [file, setFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<
    string | ArrayBuffer | null | undefined
  >(null);
  const resetRef = useRef<() => void>(null);

  const { slug } = useParams();

  const { data: agent, isLoading, refetch } = useGetAgent(slug as string);

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch: refetchUser,
  } = useGetUsers(slug as string);

  const { mutate: updateAgentImage, isLoading: isUpdating } =
    useUpdateAgentImage({
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showNotification({
          id: 'update-agent-image-failed',
          message: 'Cập nhật hình ảnh thất bại. Vui lòng thử lại.',
          color: 'red',
        });
      },
    });

  const onCloseForm = useCallback(() => {
    setSelectedUser(undefined);
    setUserFormOpened(false);
    refetchUser();
  }, []);

  const onEditUser = useCallback((user: any) => {
    setSelectedUser(user);
    setUserFormOpened(true);
  }, []);

  const onSetImageFile = useCallback((file: File) => {
    setFile(file);
    var reader = new FileReader();
    reader.onload = function (e) {
      setPreviewImage(e.target?.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const onUploadImageFile = useCallback(() => {
    if (file) {
      updateAgentImage({ id: slug as string, file });
    }
  }, [file]);

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Thông tin đại lý</Title>
      </Group>
      <SimpleGrid
        cols={2}
        spacing="xl"
        breakpoints={[{ maxWidth: 'md', cols: 1 }]}
        mb="xl"
        style={{ position: 'relative' }}
      >
        <LoadingOverlay visible={isLoading || isUsersLoading} />
        <Card shadow="md" radius="md" className={classes.card} p="xl">
          <Group position="apart" align="top">
            <Text size="lg" weight={500} className={classes.cardTitle}>
              Thông tin đại lý:
            </Text>
          </Group>
          <Group position="apart" mt="sm">
            <Text weight={600}>Tên:</Text>
            <Text>{agent?.name}</Text>
          </Group>
          <Group position="apart">
            <Text weight={600}>Email:</Text>
            <Text>{agent?.email}</Text>
          </Group>
          <Group position="apart">
            <Text weight={600}>Người đại diện:</Text>
            <Text>{agent?.representative}</Text>
          </Group>
          <Group position="apart">
            <Text weight={600}>Số điện thoại:</Text>
            <Text>{agent?.phone}</Text>
          </Group>
          <Group position="apart" spacing={0}>
            <Text weight={600}>Địa chỉ:</Text>
            <Text>
              {agent?.address}, {agent?.ward}, {agent?.district}, {agent?.city}
            </Text>
          </Group>
        </Card>
        <Card shadow="md" radius="md" className={classes.card} p="xl">
          <Stack justify="space-between">
            <img
              src={
                (previewImage as string) ||
                `${process.env.REACT_APP_API_URL}${agent?.imageUrl}` ||
                'https://via.placeholder.com/728x240.png'
              }
              height="240"
              style={{ objectFit: 'cover' }}
              alt={agent?.name}
            />
            <Group position="center">
              <FileButton
                resetRef={resetRef}
                onChange={onSetImageFile}
                accept="image/png,image/jpeg"
              >
                {(props) => (
                  <Button {...props} color="green">
                    Upload hình
                  </Button>
                )}
              </FileButton>
              <Button
                disabled={!file}
                loading={isUpdating}
                onClick={onUploadImageFile}
              >
                Lưu
              </Button>
            </Group>
          </Stack>
        </Card>
      </SimpleGrid>
      <Card shadow="md" radius="md" className={classes.card} p="xl" mb="xl">
        <Group position="apart">
          <Text size="lg" weight={500} className={classes.cardTitle}>
            Danh sách tài khoản:
          </Text>
          <Button
            size="xs"
            leftIcon={<IconPlus size={14} />}
            onClick={() => setUserFormOpened(true)}
          >
            Tạo tài khoản
          </Button>
        </Group>
        <UserTable users={users?.items || []} onEditUser={onEditUser} />
      </Card>
      <Card shadow="md" radius="md" className={classes.card} p="xl">
        <WarrantyCards
          title="Danh sách thẻ bảo hành:"
          type="plugin"
          agentId={slug}
          distributorId={agent?.distributorId}
        />
      </Card>
      <Drawer
        style={{ overflow: 'auto' }}
        opened={userFormOpened}
        onClose={onCloseForm}
        title={
          userFormOpened && (
            <Title order={3}>
              {selectedUser ? 'Cập nhật tài khoản' : 'Tạo tài khoản'}
            </Title>
          )
        }
        padding="xl"
        size="left"
        position="right"
      >
        {userFormOpened && (
          <UserForm
            user={selectedUser}
            role="AGENT"
            agentId={slug}
            onSubmitSuccess={onCloseForm}
          />
        )}
      </Drawer>
    </Container>
  );
}
