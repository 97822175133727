import { useMutation } from 'react-query'
import api from 'services/api'

import { UpdateProduct } from '../types'

const updateProduct = async (updateProduct: UpdateProduct) => {
  const submitProductData = {
    name: updateProduct.name,
    type: updateProduct.type,
    value: updateProduct.value,
  }

  await api.patch(`/products/${updateProduct.id}`, submitProductData)
}

export function useUpdateProduct() {
  return useMutation(updateProduct)
}
