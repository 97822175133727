import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { openModal } from '@mantine/modals'
import { IconFileCertificate } from '@tabler/icons'
import dayjs from 'dayjs'
import { WarrantyCard } from 'pages/warranty-cards/types'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PaginationMeta } from 'types'

interface WarrantyCardTableProps {
  path: string
  warrantyCards: WarrantyCard[]
  pagination?: PaginationMeta
  onPaginationChange: (page: number) => void
}

export function WarrantyCardTable(props: WarrantyCardTableProps) {
  const { path, warrantyCards, pagination, onPaginationChange } = props

  const rows = useMemo(() => {
    return warrantyCards.map((warrantyCard) => (
      <tr key={warrantyCard.id}>
        <td>
          <Text
            variant="link"
            component={Link}
            to={`${path}/${warrantyCard.id}`}
          >
            {warrantyCard.code}
          </Text>
        </td>
        <td>{warrantyCard.fullname}</td>
        <td>{warrantyCard.phone}</td>
        <td>
          {dayjs(warrantyCard.startDate, 'yyyy-mm-dd').format('DD-MM-YYYY')}
        </td>
        <td>
          <Group>
            <ActionIcon
              variant="light"
              title="Xem thông tin thẻ bảo hành"
              onClick={() => {
                openModal({
                  title: <Title order={3}>Thông tin thẻ bảo hành</Title>,
                  size: 900,
                  lockScroll: true,
                  children: (
                    <>
                      <iframe
                        src={`${process.env.REACT_APP_API_URL}/warranties/view/${warrantyCard.code}`}
                        style={{
                          width: '100%',
                          height: 900,
                          overflow: 'hidden',
                          border: 'none',
                        }}
                        title="Thông tin thẻ bảo hành"
                      />
                    </>
                  ),
                })
              }}
            >
              <IconFileCertificate size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [warrantyCards])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Mã thẻ</th>
            <th>Họ và tên</th>
            <th>Số điện thoại</th>
            <th>Ngày hiệu lực</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} thẻ bảo hành</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
