import { useQuery } from 'react-query'
import api from 'services/api'

const getAgents = async (distributorId: string) => {
  const { data } = await api.get('/agents', {
    params: {
      distributorId,
    },
  })

  return data
}

export function useGetAgents(distributorId: string) {
  return useQuery(['distributor-agents', distributorId], () =>
    getAgents(distributorId)
  )
}
