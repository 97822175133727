import { useQuery } from 'react-query'
import api from 'services/api'

import { GetCarsParams } from '../types'

const getCars = async (params: GetCarsParams) => {
  const { data } = await api.get('/cars', {
    params,
  })

  return data
}

export function useGetCars(carsParams: GetCarsParams) {
  return useQuery(['customer-cars', carsParams], () => getCars(carsParams))
}
