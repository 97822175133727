import {
  Button,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import vnLocal from '@natpkg/vn-local'
import { IconDeviceFloppy, IconMail, IconPhone } from '@tabler/icons'
import dayjs from 'dayjs'
import { useCreateCustomer, useUpdateCustomer } from 'pages/customers/hooks'
import { Customer } from 'pages/customers/types'
import React, { useMemo, useState } from 'react'

export interface CustomerFormProps {
  customer?: Customer
  onSubmitSuccess: () => void
}

interface CustomerFormValue {
  id: string
  fullname: string
  address: string
  ward: string
  district: string
  city: string
  email: string
  phone: string
  birthday: Date
}

const usernameRegex = /^[a-z0-9_\.]+$/
const vnPhonenumber =
  /(([03+[2-9]|05+[6|8|9]|07+[0|6|7|8|9]|08+[1-9]|09+[1-4|6-9]]){3})+[0-9]{7}\b/g

export function CustomerForm(props: CustomerFormProps) {
  const { customer, onSubmitSuccess } = props

  const { mutate: submitCreateCustomer, isLoading: isCreateCustomerLoading } =
    useCreateCustomer()
  const { mutate: submitUpdateCustomer, isLoading: isUpdateCustomerLoading } =
    useUpdateCustomer()

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      id: customer?.id || '',
      fullname: customer?.fullname || '',
      address: customer?.address || '',
      ward: customer?.ward || '',
      district: customer?.district || '',
      city: customer?.city || '',
      email: customer?.email || '',
      phone: customer?.phone || '',

      birthday: customer?.birthday
        ? dayjs(customer?.birthday, 'yyyy-mm-dd').toDate()
        : dayjs().toDate(),
    },
  })

  const handleSubmit = (values: CustomerFormValue) => {
    values.id
      ? submitUpdateCustomer(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không cập nhật được khách hàng'),
        })
      : submitCreateCustomer(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không tạo được khách hàng'),
        })
  }

  const selectedCity = form.values.city
  const selectedDistrict = form.values.district
  const selectedWard = form.values.ward

  const provinceSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    return provinces.map((item) => item.name)
  }, [])

  const districtsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const result = districts.map((item) => item.name)
    if (!result.includes(selectedDistrict)) {
      form.setFieldValue('district', '')
    }
    return result
  }, [selectedCity, selectedDistrict])

  const wardsSelectItems = useMemo(() => {
    const provinces = vnLocal.getProvinces()
    const province = provinces.find((p) => p.name === selectedCity)
    if (!province) {
      return []
    }
    const districts = vnLocal.getDistrictsByProvinceCode(province.code) || []
    const district = districts.find((d) => d.name === selectedDistrict)
    if (!district) {
      return []
    }
    const wards = vnLocal.getWardsByDistrictCode(district.code) || []
    const result = wards.map((item) => item.name)
    if (!result.includes(selectedWard)) {
      form.setFieldValue('ward', '')
    }
    return result
  }, [selectedCity, selectedDistrict, selectedWard])

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group grow align="flex-start">
          <TextInput
            required
            placeholder="Tên đầy đủ của khách hàng"
            label="Họ và tên"
            {...form.getInputProps('fullname')}
          />
          <DatePicker
            required
            placeholder="Ngày sinh nhật của người dùng"
            label="Ngày sinh"
            withAsterisk
            inputFormat="DD/MM/YYYY"
            {...form.getInputProps('birthday')}
          />
        </Group>
        <Group grow mt="md" align="flex-start">
          <TextInput
            required
            placeholder="Email của khách hàng"
            label="Email"
            type="email"
            icon={<IconMail size={16} stroke={1.5} />}
            {...form.getInputProps('email')}
          />
          <TextInput
            required
            placeholder="Số điện thoại của khách hàng"
            label="Số điện thoại"
            type="number"
            icon={<IconPhone size={16} stroke={1.5} />}
            pattern={vnPhonenumber}
            {...form.getInputProps('phone')}
          />
        </Group>

        <Stack mt="md">
          <TextInput
            required
            placeholder="Địa chỉ của khách hàng"
            label="Địa chỉ"
            {...form.getInputProps('address')}
          />
          <Group grow>
            <Select
              required
              placeholder="Tỉnh/Thành phố"
              data={provinceSelectItems}
              searchable
              {...form.getInputProps('city')}
            />
            <Select
              required
              placeholder="Quận/Huyện"
              data={districtsSelectItems}
              searchable
              {...form.getInputProps('district')}
            />
            <Select
              required
              placeholder="Phường/Xã"
              data={wardsSelectItems}
              {...form.getInputProps('ward')}
            />
          </Group>
        </Stack>

        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateCustomerLoading || isUpdateCustomerLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
