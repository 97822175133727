import { hideNotification, showNotification } from '@mantine/notifications'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/api'

import { AuthUser } from './types'

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    !!localStorage.getItem('token')
  )
  const [user, setUser] = useState<AuthUser | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    showNotification({
      id: 'load-user-info',
      message: 'Đang tải thông tin người dùng...',
      loading: true,
      color: 'blue',
      disallowClose: true,
      autoClose: false,
    })
    const loadUserInfo = async () => {
      const token = localStorage.getItem('token')
      if (token) {
        try {
          const { data: userInfo } = await api.get('users/profile')
          setUser(userInfo)
        } catch (error) {
          logout()
          showNotification({
            id: 'load-user-info-failed',
            message:
              'Tải thông tin người dùng thất bại. Vui lòng đăng nhập lại.',
            color: 'red',
          })
        }
      }
      setLoading(false)
      hideNotification('load-user-info')
    }
    loadUserInfo()
  }, [])

  const setCurrentUser = (user: AuthUser) => {
    setIsLoggedIn(true)
    setUser(user)
  }

  const logout = () => {
    setIsLoggedIn(false)
    setUser(null)
    localStorage.removeItem('token')
    navigate('/signin', { replace: true })
  }

  return { user, setCurrentUser, logout, loading, isLoggedIn }
}
