import {
  ActionIcon,
  Card,
  Container,
  createStyles,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Table,
  Text,
  Title,
} from '@mantine/core'
import { openModal } from '@mantine/modals'
import { IconFileCertificate, IconSearch } from '@tabler/icons'
import dayjs from 'dayjs'
import { useAuthContext } from 'providers'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { useGetWarrantyCard } from './hooks'

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${theme.colors.gray[1]}`,
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}))

export function WarrantyCard() {
  const { classes } = useStyles()
  const { slug } = useParams()
  const { user } = useAuthContext()

  const { data: warrantyCard, isLoading } = useGetWarrantyCard(slug as string)

  return (
    <Container fluid>
      <Group position="apart" align="center" mb="md">
        <Title>Thông tin thẻ bảo hành</Title>
      </Group>
      <div style={{ position: 'relative' }}>
        <LoadingOverlay visible={isLoading} />
        <SimpleGrid
          cols={2}
          spacing="xl"
          breakpoints={[{ maxWidth: 'md', cols: 1 }]}
          mb="xl"
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart" align="top">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Thông tin chủ thẻ:
              </Text>
              <ActionIcon
                variant="light"
                component={Link}
                to={`/customers/${warrantyCard?.customerId}`}
              >
                <IconSearch size={16} />
              </ActionIcon>
            </Group>
            <Group position="apart" mt="sm">
              <Text weight={600}>Họ và tên:</Text>
              <Text>{warrantyCard?.fullname}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Email:</Text>
              <Text>{warrantyCard?.email}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Số điện thoại:</Text>
              <Text>{warrantyCard?.phone}</Text>
            </Group>
            <Group position="apart" spacing={0}>
              <Text weight={600}>Địa chỉ:</Text>
              <Text>
                {warrantyCard?.address}, {warrantyCard?.ward},{' '}
                {warrantyCard?.district}, {warrantyCard?.city}
              </Text>
            </Group>
          </Card>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Text size="lg" weight={500} className={classes.cardTitle}>
              Thông tin xe:
            </Text>
            <Group position="apart" mt="sm">
              <Text weight={600}>Biển số xe:</Text>
              <Text>{warrantyCard?.carNumber}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Năm sản xuất:</Text>
              <Text>{warrantyCard?.carProduceYear}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Thương hiệu:</Text>
              <Text>{warrantyCard?.carBrand}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Loại xe:</Text>
              <Text>{warrantyCard?.carModel}</Text>
            </Group>
          </Card>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart" align="top">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Thông tin thẻ bảo hành:
              </Text>
              <ActionIcon
                variant="light"
                title="Xem thông tin thẻ bảo hành"
                onClick={() => {
                  openModal({
                    title: <Title order={3}>Thông tin thẻ bảo hành</Title>,
                    size: 900,
                    lockScroll: true,
                    children: (
                      <>
                        <iframe
                          src={`${process.env.REACT_APP_API_URL}/warranties/view/${warrantyCard.code}`}
                          style={{
                            width: '100%',
                            height: 900,
                            overflow: 'hidden',
                            border: 'none',
                          }}
                          title="Thông tin thẻ bảo hành"
                        />
                      </>
                    ),
                  })
                }}
              >
                <IconFileCertificate size={16} />
              </ActionIcon>
            </Group>

            <Group position="apart" mt="sm">
              <Text weight={600}>Mã thẻ:</Text>
              <Text>{warrantyCard?.code}</Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Bảo hành kỹ thuật:</Text>
              <Text>
                {dayjs(warrantyCard?.startDate, 'yyyy-mm-dd')
                  .add(1, 'year')
                  .format('DD-MM-YYYY')}
              </Text>
            </Group>
            <Group position="apart">
              <Text weight={600}>Bảo hành phim:</Text>
              <Text>
                {dayjs(warrantyCard?.startDate, 'yyyy-mm-dd')
                  .add(10, 'year')
                  .format('DD-MM-YYYY')}
              </Text>
            </Group>
          </Card>
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <Group position="apart" align="top">
              <Text size="lg" weight={500} className={classes.cardTitle}>
                Thông tin nhà phân phối:
              </Text>
              {(user?.role === 'ADMIN' || user?.role === 'DISTRIBUTOR') &&
                warrantyCard?.agent && (
                  <ActionIcon
                    variant="light"
                    component={Link}
                    to={`/agents/${warrantyCard?.agentId}`}
                  >
                    <IconSearch size={16} />
                  </ActionIcon>
                )}
            </Group>
            {warrantyCard?.agent ? (
              <>
                <Group position="apart" mt="sm">
                  <Text weight={600}>Tên:</Text>
                  <Text>{warrantyCard?.agent.name}</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Email:</Text>
                  <Text>{warrantyCard?.agent.email}</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Số điện thoại:</Text>
                  <Text>{warrantyCard?.agent.phone}</Text>
                </Group>
                <Group position="apart">
                  <Text weight={600}>Địa chỉ:</Text>
                  <Text>
                    {warrantyCard?.agent.address}, {warrantyCard?.agent.ward},{' '}
                    {warrantyCard?.agent.district}, {warrantyCard?.agent.city}
                  </Text>
                </Group>
              </>
            ) : (
              <Text mt="sm">----</Text>
            )}
          </Card>
        </SimpleGrid>
        <Card shadow="md" radius="md" className={classes.card} p="xl">
          <Text size="lg" weight={500} className={classes.cardTitle}>
            Thông tin các hạng mục:
          </Text>
          <Table mt="sm">
            <thead>
              <tr>
                <th>Kính lái</th>
                <th>Sườn trước</th>
                <th>Sườn sau</th>
                <th>Kính lưng</th>
                <th>Kính nóc</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{warrantyCard?.windscreen}</td>
                <td>{warrantyCard?.frontDoors}</td>
                <td>{warrantyCard?.backDoors}</td>
                <td>{warrantyCard?.rearWindow}</td>
                <td>{warrantyCard?.skyWindow}</td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </div>
    </Container>
  )
}
