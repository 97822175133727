import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import { IconEdit } from '@tabler/icons'
import { Customer } from 'pages/customers/types'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PaginationMeta } from 'types'

interface CustomerTableProps {
  customers: Customer[]
  pagination?: PaginationMeta
  onEditCustomer: (customer: Customer) => void
  onPaginationChange: (page: number) => void
}

export function CustomerTable(props: CustomerTableProps) {
  const { customers, pagination, onEditCustomer, onPaginationChange } = props

  const rows = useMemo(() => {
    return customers.map((customer) => (
      <tr key={customer.id}>
        <td>
          <Text
            variant="link"
            component={Link}
            to={`/customers/${customer.id}`}
          >
            {customer.fullname}
          </Text>
        </td>
        <td>{customer.phone}</td>
        <td>{customer.email}</td>
        <td>
          <Group>
            <ActionIcon
              color="green"
              variant="light"
              title="Cập nhật người dùng"
              onClick={() => onEditCustomer(customer)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    ))
  }, [onEditCustomer, customers])

  return (
    <Stack>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>Họ và tên</th>
            <th>Số điện thoại</th>
            <th>Email</th>
            <th style={{ width: 40 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Group position="apart">
        <Text>{pagination?.totalItems} khách hàng</Text>
        <Pagination
          total={pagination?.totalPages || 0}
          size="sm"
          onChange={onPaginationChange}
        />
      </Group>
    </Stack>
  )
}
