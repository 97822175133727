import {
  Button,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconDeviceFloppy, IconLock, IconUser } from '@tabler/icons'
import { useCreateUser, useUpdateUser } from 'pages/users/hooks'
import { ROLE, User } from 'pages/users/types'
import React, { useState } from 'react'

export interface UserFormProps {
  user?: User
  onSubmitSuccess: () => void
  role?: ROLE
  distributorId?: string
  agentId?: string
}

interface UserFormValue {
  id: string
  fullname: string
  username: string
  password: string
  confirmPassword: string
  role: ROLE

  agentId?: string
  distributorId?: string
}

const usernameRegex = /^[a-z0-9_\.]+$/
const vnPhonenumber =
  /(([03+[2-9]|05+[6|8|9]|07+[0|6|7|8|9]|08+[1-9]|09+[1-4|6-9]]){3})+[0-9]{7}\b/g

export function UserForm(props: UserFormProps) {
  const {
    user,
    onSubmitSuccess,
    role: roleType,
    distributorId,
    agentId,
  } = props

  const { mutate: submitCreateUser, isLoading: isCreateUserLoading } =
    useCreateUser()
  const { mutate: submitUpdateUser, isLoading: isUpdateUserLoading } =
    useUpdateUser()

  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    initialValues: {
      id: user?.id || '',
      fullname: user?.fullname || '',
      username: user?.username || '',
      password: '',
      confirmPassword: '',
      role: user?.role || roleType || 'AGENT',

      agentId: user?.agentId || agentId,
      distributorId: user?.distributorId || distributorId,
    },
    validate: {
      username: (value: string) =>
        value.trim().length < 5
          ? 'Quá ngắn'
          : !usernameRegex.exec(value)
          ? 'Tên đăng nhập không hợp lệ'
          : null,
      confirmPassword: (value, values) =>
        value !== values.password ? 'Mật khẩu không đúng' : null,
    },
  })

  const handleSubmit = (values: UserFormValue) => {
    values.id
      ? submitUpdateUser(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không cập nhật được người dùng'),
        })
      : submitCreateUser(values, {
          onSuccess: () => onSubmitSuccess(),
          onError: () => setError('Không tạo được người dùng'),
        })
  }

  return (
    <Paper>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group grow align="flex-start">
          <TextInput
            required
            placeholder="Tên đầy đủ"
            label="Họ và tên"
            {...form.getInputProps('fullname')}
          />
          <TextInput
            required
            placeholder="Tên đăng nhập"
            label="Tên đăng nhập"
            icon={<IconUser size={16} stroke={1.5} />}
            {...form.getInputProps('username')}
            disabled={!!user}
          />
        </Group>
        <PasswordInput
          mt="md"
          required={!user}
          placeholder="Mật khẩu"
          label="Mật khẩu"
          icon={<IconLock size={16} stroke={1.5} />}
          {...form.getInputProps('password')}
        />

        <PasswordInput
          mt="md"
          required={!user}
          label="Xác nhận mật khẩu"
          placeholder="Xác nhận mật khẩu"
          icon={<IconLock size={16} stroke={1.5} />}
          {...form.getInputProps('confirmPassword')}
        />

        {error && (
          <Text color="red" size="sm" mt="sm">
            {error}
          </Text>
        )}

        <Group position="right">
          <Button
            type="submit"
            mt="md"
            leftIcon={<IconDeviceFloppy size={14} />}
            loading={isCreateUserLoading || isUpdateUserLoading}
          >
            Lưu thông tin
          </Button>
        </Group>
      </form>
    </Paper>
  )
}
